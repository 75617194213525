import api from "./api";

export default async function getProjects() {
    try {
        const response = await api.get('api/report-card/project/');
        return response.data.results;
    } catch (error) {
        console.error(error);
    }
  }
