<template>
  <div id="container">
    <div class="title-line">
      <h2>Мои таймшиты</h2>
      <div>
        <select class="select-year" v-model="this.year" @change="handleChangeYear">
          <option v-for="year in years" :key="year" :value="[year]">{{ year }}</option>
        </select>
      </div>
      <div>
        <select class="select-month" v-model="this.month" @change="handleChangeMonth">
          <option v-for="month in months" :key="month.number" :value="[month.number]">{{ month.title }}</option>
        </select>
      </div>
    </div>
    <div v-if="data_months" id="sheet">
      <div id="left-box">
        <table v-if="data_months">
          <thead>
            <tr>
              <th></th>
              <th>Проект</th>
            </tr>
          </thead>
          <tbody id="left-box-body">
            <tr v-for="month in data_months" :key="month.id">
              <td><LockerButton
                @childEvent="getData"
                :employee_closed="month.frozen_employee"
                :manager_closed="month.frozen_manager"
                :id="month.id"
                :holder="'E'"
              /></td>
              <td>{{ month.project.name }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="d-flex">
        <div id="sum">
          <table>
            <thead>
              <tr>
                <td>Часы факт</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sum, index) in this.sum_months" :key="index">
                <td>{{ sum }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr><td></td></tr>
            </tfoot>
          </table>
        </div>
        <div id="right-box">
          <table>
            <thead>
              <tr>
                <td v-for="day in Array.from({length: 31}, (_, i) => i + 1)" :key="day">{{ day }}</td>
              </tr>
            </thead>
            <tbody id="right-box-body">
              <tr v-for="month in data_months" :key="month.id">
                <td v-for="(day, day_) in month.days.days" :key="day_">
                  <input 
                    :disabled="month.frozen_employee"
                    type="text"
                    maxlength="2"
                    :old_value="day"
                    :value="day"
                    :month_id="month.id"
                    :day="day_ + 1"
                    @focus="selectAllContent"
                    @focusout="checkInput"
                  >
                </td>
              </tr>
             </tbody>
            <tfoot>
              <tr>
                <td v-for="(sum, index) in this.sum_days" :key="index">{{ sum }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div v-else>Идет загрузка данных ...</div>
  </div>
</template>

<script>
  import api from '@/api/api.js';
  import LockerButton from './LockerButton.vue';
  import _ from 'lodash';

  export default {
    name: 'TimeSheetsUser',
    components: { LockerButton },
    data() {
      return {
        data_months: [],
        free_projects: [],
        sum_days: [],
        sum_months: [],
        selectedProject: '',
        year: [new Date().getFullYear()],
        month: [new Date().getMonth() + 1],
        months: [
          {title: 'Январь', number: 1},
          {title: 'Февраль', number: 2},
          {title: 'Март', number: 3},
          {title: 'Апрель', number: 4},
          {title: 'Май', number: 5},
          {title: 'Июнь', number: 6},
          {title: 'Июль', number: 7},
          {title: 'Август', number: 8},
          {title: 'Сентябрь', number: 9},
          {title: 'Октябрь', number: 10},
          {title: 'Ноябрь', number: 11},
          {title: 'Декабрь', number: 12}
        ],
        years: [2024, 2023, 2022, 2021, 2020]
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getDataSleep: _.debounce(async () => {
        this.getData();
      }, 1200),
      async getData() {
        var url = `api/report-card/month/me/?year=${this.year}&month=${this.month}&ordering=project__name`;
        await api.get(url)
          .then(response => {
            this.data_months = response.data.results;
            this.setSums();
        }).catch(error => {
          console.log(error.message);
        });
      },
      async checkInput(event) {
        var old_value = event.target.getAttribute('old_value');
        this.$nextTick(() => {
          if (event.target.value != old_value) {
            var day_type = !isNaN(event.target.value) ? 1:
            {
              'В': 2,
              'Б': 3,
              'О': 4,
            }[event.target.value] || null;
            if (day_type !== null) {
              this.createDate(
                event.target.getAttribute('day'),
                event.target.getAttribute('month_id'),
                day_type,
                day_type == 1 ? event.target.value : 0
                );
                event.target.setAttribute('old_value', event.target.value);
              } else {
              event.target.value = old_value;
              alert('Неккоректное значение. \nВ - Выходной\nО - Отпуск\nБ - Больничный');
            }
          }
        });
      },
      selectAllContent(event) {
        event.target.select();
      },
      handleChangeMonth(event) {
        this.month = [event.target.value];
        this.getData();
      },
      handleChangeYear(event) {
        this.year = [event.target.value];
        this.getData();
      },
      setSums() {
        this.sum_days = [];
        this.sum_months = [];
        if (!this.data_months.length) {
          return
        }
        var sum_days = [];
        var sum_months = [];
        const days = this.data_months[0].days.days.length;
        for (var i = 0; i < this.data_months.length; i++) {
          for (var ii = 0; ii < days; ii++) {
            var day = this.data_months[i].days.days[ii];
            day = (day > 0) ? day : 0

            if (sum_days[ii]) {
              sum_days[ii] = sum_days[ii] + day
            } else {
              sum_days[ii] = day
            }

            if (sum_months[i]) {
              sum_months[i] = sum_months[i] + day
            } else {
              sum_months[i] = day
            }
          }
        }
        this.sum_days = sum_days;
        this.sum_months = sum_months;
      },
      async createDate(day, month_id, day_type, hours) {
        const debouncedGetData = _.debounce(() => {
          this.getData();
        }, 1200);

        try {
          await api.post('api/report-card/day/', {
            day: day,
            year: this.year,
            month: this.month,
            month_id: month_id,
            hours: hours,
            day_type: day_type,
          });
          debouncedGetData();
        } catch (err) {
          console.log(err.message);
        }

      },
    }
  }
</script>

<style lang="scss" scoped>
#container {
  margin: 40px;
  border-radius: 20px;
  background-color: white;
  padding: 30px;
  overflow: hidden;
  overflow-x: scroll;
  
  .title-line {
    display: flex;
    justify-items: baseline;
    margin-bottom: 20px;
    div {
      margin: 0 20px;
    }
    h2 {
      margin: 0;
    }
    .select-year, .select-month {
      text-align: center;
      font-size: 20px;
      color: rgb(80, 80, 80);
      height: 40px;
      border-radius: 18px;
      border-color: rgb(205, 205, 205);
      padding: 0 20px;
    }
  }

  #sheet {
    display: flex;
    border: 1px solid lightgray;
    border-radius: 20px;
    overflow: hidden;
    width: max-content;

    table {
      text-align: left;
    }
    
    td, th {
      padding-left: 14px;
      padding-right: 14px;
      border-left: none;
      border-bottom: 1px solid lightgray;
      border-right: 1px solid lightgray;
      padding-top: 4px;
      padding-bottom: 4px;
      height: 40px;
    }
    tfoot {
      background-color: rgb(230, 230, 230);
    }

    #right-box {
      table {
        td, th {
          width: 34px;
          position: relative;
          text-align: center;
          
        }
      }
      input {
        text-align: center;
        position: absolute;
        border: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      input::selection {
        background-color: black;
        color: white;
      }
    }
  }
}
</style>