<template>
  <MainCard>
    <form @submit.prevent="changePassword">
      <div class="mb-3">
        <label>Старый пароль</label>
        <input v-model="passwordOld" type="password" class="form-control" required>
        <div v-if="errors && errors.password">
          <small v-for="(msg, index) in errors.password" :key="index" id="emailHelp" class="form-text text-danger">{{ msg }}</small>
        </div>
      </div>
      <hr>
      <div class="mb-3">
        <label>Новый пароль</label>
        <input v-model="password1" type="password" class="form-control" required>
      </div>
      <div class="mb-3">
        <label>Повторите новый пароль</label>
        <input v-model="password2" type="password" class="form-control" required>
      </div>
      <button type="submit" class="btn btn-primary">Измененить пароль</button>
    </form>
  </MainCard>
</template>

<script>
  import api from '@/api/api';
import MainCard from '@/components/MainCard.vue';

  export default {
    components: { MainCard },
    data() {
      return {
        passwordOld: '',
        password1: '',
        password2: '',
        errors: {}
      }
    },
    methods: {
      changePassword() {
        if (this.password1 != this.password2) {
          this.errors.password1 = ['Пароли не равны!']
        } else {
          this.sendForm();
        }
      },
      async sendForm() {
        await api.post('api/user/change-password/', {
          password: this.passwordOld,
          password_new: this.password1
        }).then(() => {
          this.errors = [];
          this.$router.push('/');
        }).catch(err => {
          console.log(err);
          this.errors = err.response.data;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>