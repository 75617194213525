<template>
    <div>
        <TimeSheetsUser />
    </div>
</template>

<script>
import TimeSheetsUser from '@/components/TimeSheetsUser.vue';

export default {
    name: 'ReportUserPage',
    components: {
        TimeSheetsUser,
    }
}
</script>

<style lang="scss" scoped>

</style>