<template>
    <div>
        <TimeSheets />
    </div>
</template>

<script>
import TimeSheets from '@/components/TimeSheets.vue';

export default {
    name: 'ReportManagerPage',
    components: {
        TimeSheets,
    }
}
</script>

<style lang="scss" scoped>

</style>