import { createRouter, createWebHashHistory } from 'vue-router';
import { isLoggedIn } from '../utils/auth';
import routes from './routes';
import store from '@/store';

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  try {
    if (to.meta.requiresAuth) {
      if (!isLoggedIn()) {
        console.log('Non authorized!');
        next({path: '/login'});
      }
      if (to.meta.min_role && store.state.user.role < to.meta.min_role) {
        console.log('Permission Denied!');
        next({path: '/not-found'});
      }
    }
    next();
  } catch {
    next({path: '/'});
  }
});

export default router;
