<template>
<div :class="this.isDisable? 'locker disable':'locker'" @click="this.isDisable ? null : toggleLocker()">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
<path v-if="this.isClose" d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
<path v-else d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z"/>
</svg>
</div>
</template>

<script>
import api from '@/api/api';

export default {
  name: 'LockerButton',
  props: {
    employee_closed: {
      type: Boolean,
      default: false
    },
    manager_closed: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, undefined],
      default: undefined
    },
    holder: {
      type: String,
      default: 'E'
    },
  },
  data() {
    return {
        isClose: false,
        isDisable: true,
      }
  },
  created() {
    this.checkAccess();
    this.isClose = this.holder == 'E' ? this.employee_closed : this.manager_closed
  },
  methods: {
    async toggleLocker() {
      await api.patch(`api/report-card/month/${this.id}/`, this.getBodyForMonth())
      .then(() => {
        this.isClose = !this.isClose;
        this.$emit('childEvent');
        }).catch( error => {console.log(error.message);});
    },
    getBodyForMonth() {
      return this.holder == 'E' ? {
        frozen_employee: !this.isClose,
      } : {
        frozen_manager: !this.isClose,
      };
    },
    checkAccess() {
      if (this.holder == 'E') {
        if (!this.manager_closed) {
          this.isDisable = false;
        }
      } else {
        if (this.$store.state.user.role != this.$store.state.roles.admin) {
          return
        }
        if (this.employee_closed) {
          this.isDisable = false;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.locker {
  cursor: pointer;
}
.disable {
  color: gray;
  cursor: not-allowed;
}
</style>
