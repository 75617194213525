import router from "@/router/router";
import axios from "axios";

const api = axios.create({
  baseURL: 'https://smartbackoffice.ru',
  // baseURL: 'http://localhost:8000',
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (error) {
  console.log('before error');
  return Promise.reject(error);
});


api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ((error.response.status === 401)
      && !(['login', 'register'].includes(router.currentRoute.value.name))) {
      localStorage.removeItem('token');
      location.reload();
    }
    return Promise.reject(error);
  });


export default api;
