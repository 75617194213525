<template>
  <HeaderMain />
  <router-view />
</template>

<script>
import HeaderMain from '@/components/HeaderMain.vue';
import { mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    HeaderMain,
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.setAllSetters();
    }
  },
  methods: {
    ...mapActions([
      'setAllSetters'
    ])
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap');
* {
  margin: 0;
  padding: 0;
}
body {
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  background: #F5F7F9;
}
</style>
