<template>
    <div class="d-flex align-items-center justify-content-center">
      <div class="text-center">
        <h1 class="display-1 fw-bold">404</h1>
        <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
        <p class="lead">
          The page you’re looking for doesn’t exist.
        </p>
        <hr>
        <button class="btn btn-primary w-100" @click="history.back()">Go Back</button>
      </div>
    </div>
  </template>
  
  <script>
    export default {
    }
  </script>
  
  <style lang="scss" scoped>
  </style>