<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>Менеджер</th>
                    <th>Дата изменения</th>
                    <th>Заблокированно сотрудником</th>
                    <th>Заблокированно менеджером</th>
                    <th>Согласовано</th>
                    <th>Оплачено</th>
                    <th>Модуль</th>
                    <th>Тип работ</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="month in history" :key="month.id">
                    <td>{{ month.modified_by?month.modified_by.full_name:'' }}</td>
                    <td>{{ month.history_date.slice(0, 10) }} - {{ month.history_date.slice(11, 19) }}</td>
                    <td>{{ month.frozen_employee?'Да':'Нет' }}</td>
                    <td>{{ month.frozen_manager?'Да':'Нет' }}</td>
                    <td>{{ month.agreed?agreeds[month.agreed]:agreeds[0] }}</td>
                    <td>{{ month.paid?paids[month.paid]:paids[0] }}</td>
                    <td>{{ month.module?month.module.name:'' }}</td>
                    <td>{{ month.works_type?works_types[month.works_type]:works_types[0] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import api from '@/api/api';

    export default {
        data() {
            return {
                history: [],
                agreeds: [
                    '',
                    'Ожидаем',
                    'Не согласовано',
                    'Согласовано',
                    'Не работал'
                ],
                works_types: [
                    '',
                    'НИР',
                    'ТР'
                ],
                paids: [
                    '',
                    'Оплачено',
                    'В оплате',
                    'Нет чека',
                    'Не платим',
                    'Не работал',
                ],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            async getData() {
                var url = 'api/report-card/month/history/' + this.$route.params.id + '/';
                
                await api.get(url)
                .then(response => {
                    this.history = response.data.results;
                    console.log(this.history);
                }).catch(error => {
                    console.log(error);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>