<template>
  <div id="container">
      
    <div class="title-line">
      <select class="select" v-model="this.year" id="selectYear" @change="getData()" multiple>
        <option v-for="year in years" :key="year" :value="year" :selected="this.year == year">{{ year }}</option>
      </select>
      <select class="select" v-model="this.month" id="selectMonth" @change="getData()" multiple>
        <option v-for="month in months" :key="month.number" :value="month.number" :selected="this.month == month.number">{{ month.title }}</option>
      </select>
      <select v-if="this.employeesList" class="select" id="selectEmployees" @change="getData()" multiple>
        <option v-for="emp in this.employeesList" :key="emp.id" :value="emp.id">{{ emp.full_name }}</option>
      </select>
      <select v-if="this.$store.state.projects" class="select" id="selectProjects" @change="getData()" multiple>
        <option v-for="proj in this.$store.state.projects" :key="proj.id" :value="proj.id">{{ proj.name }}</option>
      </select>
      <select v-if="this.modulesList" class="select" id="selectModules" @change="getData()" multiple>
        <option v-for="mod in this.modulesList" :key="mod.id" :value="mod.id">{{ mod.name }}</option>
      </select>
      <div>
        <div class="d-flex flex-column">
          <a @click="getData(1, 'exel')" class="btn btn-outline-success m-1">Скачать .xlsx</a>
        </div>
      </div>
    </div>

    <div v-if="data_months">

      <div id="sheet">
        <div id="left-box">
          <div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Согласование</th>
                  <th>Статус оплаты</th>
                  <th class="sort-button" @click="setOrdering('employee__full_name')">ФИО Исполнителя</th>
                  <th class="sort-button" @click="setOrdering('specialization__title')">Специализация</th>
                  <th class="sort-button" @click="setOrdering('project__name')">Проект</th>
                  <th class="sort-button">
                    <div class="w-100 d-flex justify-content-between">
                      <span @click="setOrdering('module__name')">Модуль</span>
                      <span @click="toggleModuleField">{{ shortModuleField ? '>' : '<' }}</span>
                    </div>
                  </th>
                  <th class="sort-button" @click="setOrdering('works_type')">Тип работ</th>
                  <th v-if="manager">h</th>
                  <th class="sort-button" @click="setOrdering('date')">Дата</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="month in data_months" :key="month.id">
                  <td><LockerButton
                      :employee_closed="month.frozen_employee"
                      :manager_closed="month.frozen_manager"
                      :id="month.id"
                      :holder="'M'"
                    /></td>
                  <td class="select-box">
                    <select class="select-inp" @change="toggleAgreed(month.id, $event)" :disabled="this.$store.state.user.role < this.$store.state.roles.admin">
                      <option selected :value="null"></option>
                      <option v-for="option in agreeds" :value="option.id" :key="option.id" :selected="option.id == month.agreed">{{ option.title }}</option>
                    </select>
                  </td>
                  <td class="select-box">
                    <select  class="select-inp" @change="togglePaid(month.id, $event)" :disabled="this.$store.state.user.role != this.$store.state.roles.manager">
                      <option selected :value="null"></option>
                      <option v-for="option in paids" :value="option.id" :key="option.id" :selected="option.id == month.paid">{{ option.title }}</option>
                    </select>
                  </td>
                  <td>{{ month.employee.full_name }}</td>
                  <td v-if="month.specialization">{{ month.specialization.title }}</td>
                  <td v-else>undefind</td>
                  <td v-if="month.project">{{ month.project.name }}</td>
                  <td v-else>undefind</td>
                  <td v-if="month.project && month.project.modules" :class="'select-box' +  (shortModuleField ? ' select-module' : '')">
                    <select  class="select-inp" @change="toggleModule(month.id, $event)" :disabled="this.$store.state.user.role < this.$store.state.roles.admin">
                      <option :selected="month.module?false:true" :value="null"></option>
                      <option v-for="option in month.project.modules" :value="option.id" :key="option.id" :selected="option.id == (month.module?month.module.id:0)">{{ option.name }}</option>
                    </select>
                  </td>
                  <td v-else>undefind</td>
                  
                  <td class="select-box">
                    <select  class="select-inp" @change="toggleWorksType(month.id, $event)" :disabled="this.$store.state.user.role < this.$store.state.roles.admin">
                      <option selected :value="null"></option>
                      <option v-for="option in works_types" :value="option.id" :key="option.id" :selected="option.id == month.works_type">{{ option.title }}</option>
                    </select>
                  </td>
                  <td class="link" v-if="manager" @click="$router.push({name: 'History', params: {id: month.id}})">h</td>
                  <td class="text-sm">{{ month.date.slice(0, -3) }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <th v-if="manager"></th>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="sum-col">
            <table>
              <thead>
                <tr>
                  <td>Часы план</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dh, index) in this.data_months" :key="index">
                  <td>{{ dh.days.plan_hours }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr><td>{{ totalPlanHours }}</td></tr>
              </tfoot>
            </table>
          </div>
          <div class="sum-col">
            <table>
              <thead>
                <tr>
                  <td>Часы факт</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sum, index) in this.sum_months" :key="index">
                  <td>{{ sum }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr><td>{{ totalFactHours }}</td></tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="d-flex">
          <div id="right-box">
            <table>
              <thead>
                <tr>
                  <td v-for="day in Array.from({length: 31}, (_, i) => i + 1)" :key="day">{{ day }}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="month in data_months" :key="month.id">
                  <td v-for="(day, index_in) in month.days.days" :key="index_in" :class="getBGColor(day)">{{ day }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td v-for="(sum, index) in this.sum_days" :key="index">{{ sum }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="pagination-group p-3">
        <button v-for="item in Array.from({length: (allItems/itemsPerPage)+1}, (_, i) => i + 1)" :key="item" 
          @click="getData(item)" 
          :class="'btn m-1 btn-' + ((selectedPage == item)?'':'outline-') + 'dark'">
          {{ item }}
        </button>
      </div>
    </div>

    <div v-else>Идет загрузка данных ...</div>

  </div>
</template>
    
<script>
  import api from '@/api/api.js';
  import LockerButton from './LockerButton.vue';

  export default {
    name: "TimeSheets",
    components: { LockerButton },
    data() {
      return {
        manager: false,
        data_months: [],
        employees: '',
        employeesList: [],
        projects: '',
        modules: '',
        modulesList: [],
        selectedProject: "",
        ordering: 'employee__full_name',
        sum_days: [],
        sum_months: [],
        shortModuleField: true,
        year: [new Date().getFullYear()],
        month: [new Date().getMonth() + 1],
        months: [
            { title: "Январь", number: 1 },
            { title: "Февраль", number: 2 },
            { title: "Март", number: 3 },
            { title: "Апрель", number: 4 },
            { title: "Май", number: 5 },
            { title: "Июнь", number: 6 },
            { title: "Июль", number: 7 },
            { title: "Август", number: 8 },
            { title: "Сентябрь", number: 9 },
            { title: "Октябрь", number: 10 },
            { title: "Ноябрь", number: 11 },
            { title: "Декабрь", number: 12 }
        ],
        years: [2024, 2023, 2022, 2021, 2020],
        agreed: null,
        agreeds: [
          {id:1, title: 'Ожидаем'},
          {id:2, title: 'Не согласовано'},
          {id:3, title: 'Согласовано'},
          {id:4, title: 'Не работал'}
        ],
        works_types: [
          {
            id: 1,
            title: 'НИР'
          },
          {
            id: 2,
            title: 'ТР'
          },
        ],
        paid: null,
        paids: [
          {id:1, title: 'Оплачено'},
          {id:2, title: 'В оплате'},
          {id:3, title: 'Нет чека'},
          {id:4, title: 'Не платим'},
          {id:5, title: 'Не работал'}
        ],
        itemsPerPage: 60,
        allItems: 0,
        selectedPage: 1,
      };
    },
    created() {
      this.getData();
      this.manager = (this.$store.state.user.role > 1)?true:false;
    },
    computed: {
      totalPlanHours() {
        return this.data_months.reduce((sum, dh) => sum + dh.days.plan_hours, 0);
      },
      totalFactHours() {
        return this.sum_months.reduce((total, number) => total + number, 0);
      }
    },
    methods: {
      async getData(selectedPage = 1, file = '') {
        if (!file) {
          this.data_months = null;
        }
        this.setModules();
        this.setProjects();
        this.setEmployees();
        this.setMonth();
        this.setYear();
        var url = 'api/report-card/month/?' +
                    `limit=${this.itemsPerPage}&` +
                    `offset=${(selectedPage - 1) * this.itemsPerPage}&` +
                    `year=${this.year}&` +
                    `month=${this.month}&` +
                    `employees=${this.employees}&` +
                    `projects=${this.projects}&` +
                    `modules=${this.modules}&` +
                    `ordering=${this.ordering}&` +
                    (file?`file=${file}`:'');

        let responseType = file === 'exel' ? 'blob' : 'json';

        await api.get(url, {
          responseType: responseType
        }).then(response => {
          if (file === 'exel') {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Kairos Report.xlsx'); // Вы можете задать другое имя файла
            document.body.appendChild(link);
            link.click();
          } else {
            console.log(response);
            this.data_months = response.data.results;
            this.employeesList = response.data.employees;
            this.allItems = response.data.count;
            this.setSums();
            this.selectedPage = selectedPage;
          }
        }).catch(error => {
          console.log(error);
        });
      },
      getBGColor(day) {
        if (day == 'В') {
          return 'b-g'
        } else if (day > 8) {
          return 'b-r'
        } else if (day < 8) {
          return 'b-y'
        } else {
          return ''
        }
      },
      setSums() {
        this.sum_days = [];
        this.sum_months = [];

        if (!this.data_months.length) {
          return
        }

        const days = this.data_months[0].days.days.length;
        var sum_days = [];
        var sum_months = [];

        for (var i = 0; i < this.data_months.length; i++) {
          for (var ii = 0; ii < days; ii++) {
            var day = this.data_months[i].days.days[ii];
            day = (day > 0) ? day : 0

            if (sum_days[ii]) {
              sum_days[ii] = sum_days[ii] + day
            } else {
              sum_days[ii] = day
            }

            if (sum_months[i]) {
              sum_months[i] = sum_months[i] + day
            } else {
              sum_months[i] = day
            }
          }
        }
        this.sum_days = sum_days;
        this.sum_months = sum_months;
      },
      setModules() {
        const elem = document.getElementById('selectModules');
        if (elem) {
          this.modules = Array.from(elem.selectedOptions).map(option => option.value);
        }
      },
      setProjects() {
        const elem = document.getElementById('selectProjects');
        if (elem) {
          this.projects = Array.from(elem.selectedOptions).map(option => Number(option.value));

        }
        var mm = [];
        for (var ii = 0; ii < this.$store.state.projects.length; ii++) {
          if (this.projects.includes(this.$store.state.projects[ii].id)) {
            mm = [...mm, ...this.$store.state.projects[ii].modules];
          }
        }
        this.modulesList = mm;
        this.modules = [];
      },
      setEmployees() {
        const elem = document.getElementById('selectEmployees');
        if (elem) {
          this.employees = Array.from(elem.selectedOptions).map(option => option.value);
        }
      },
      setMonth() {
        const elem = document.getElementById('selectMonth');
        if (elem) {
          this.month = Array.from(elem.selectedOptions).map(option => option.value);
        }
      },
      setYear() {
        const elem = document.getElementById('selectYear');
        if (elem) {
          this.year = Array.from(elem.selectedOptions).map(option => option.value);
        }
      },
      setOrdering(ordering) {
        if (this.ordering == ordering && this.ordering[0] != '-') {
          this.ordering = '-' + this.ordering;
        } else {
          this.ordering = ordering;
        }
        this.getData();
      },
      toggleLocker(index) {
        const inp1 = document.getElementById('ch1-' + index);
        const inp2 = document.getElementById('ch2-' + index);
        if (inp1.disabled === true) {
          inp1.removeAttribute('disabled');
          inp2.removeAttribute('disabled');
        } else {
          inp1.setAttribute('disabled', true);
          inp2.setAttribute('disabled', true);
        }

      },
      toggleAgreed(month_id, event) {
        console.log(event);
        api.patch(`api/report-card/month/${month_id}/`, {
          agreed: event.target.value
        }).then(() => {
        }).catch(error => {
          console.log(error);
        })
      },
      toggleModule(month_id, event) {
        api.patch(`api/report-card/month/${month_id}/`, {
          module: event.target.value
        }).then(() => {
        }).catch(error => {
          console.log(error);
        })
      },
      togglePaid(month_id, event) {
        api.patch(`api/report-card/month/${month_id}/`, {
          paid: event.target.value
        }).then(() => {
        }).catch(error => {
          console.log(error);
        })
      },
      toggleWorksType(month_id, event) {
        api.patch(`api/report-card/month/${month_id}/`, {
          works_type: event.target.value
        }).then(() => {
        }).catch(error => {
          console.log(error);
        })
      },
      toggleModuleField() {
        this.shortModuleField = !this.shortModuleField;
      }
    },
}
</script>

<style lang="scss" scoped>
select {
  background-color: rgba(0,0,0,0);
}
#selectEmployees {
  min-width: 312px;
}
#container {
  margin: 20px;
  padding-bottom: 100px;

  .title-line {
    display: flex;
    justify-items: baseline;
    margin-bottom: 20px;

    .select {
      margin: 6px;
      height: 200px;
      padding: 6px 20px;
      border: none;
    }
    .select::-webkit-scrollbar {
      // background-color: rgb(211, 211, 211);
      width: 6px;
    }
    .select::-webkit-scrollbar-thumb {
      background-color: rgb(206, 206, 206);
      border-radius: 3px;
    }
  }
  #sheet {
    display: flex;
    border: 1px solid lightgray;
    border-radius: 20px;
    // overflow: hidden;
    width: max-content;

    table {
      text-align: left;
      
      .sort-button {
        cursor: pointer;
      }
      .sort-button:hover {
        color: gray;
      }

      .text-sm {
        font-family: monospace;
        font-size: 11px;
      }

      td, th {
        border-bottom: 1px solid lightgray;
        border-right: 1px solid lightgray;
        padding-top: 4px;
        padding-bottom: 4px;
        height: 40px;
      }
      tfoot {
        background-color: rgb(230, 230, 230);
      }
      thead {
        position: sticky;
        top: 0;
        z-index: 10;

        background-color: rgb(250, 250, 250);
      }
    }

    #left-box {
      display: flex;
      table {
        .link {
          cursor: pointer;
          text-decoration: underline;
        }
        td, th {
          padding-left: 14px;
          padding-right: 14px;
        }
        .select-box {
          padding: 2px;

          select {
            border: none;
            height: 100%;
            width: 100%;
          }
        }

        .select-module {
          max-width: 250px;
        }

      }
      .sum-col {
        border-right: 2px solid rgb(214, 214, 214);
      }
    }
    #right-box {
      table {
        td, th {
          width: 34px;
          text-align: center;
        }
        .b-g {
          background-color: rgb(243, 243, 243);
        }
        .b-r {
          background-color: rgb(255, 205, 205);
        }
        .b-y {
          background-color: rgb(255, 254, 189);
        }
      }
    }
    #sum {
      
      table {
        text-align: center;
        border-radius: 0 20px 20px 0;
      }
    }
  }
}
</style>