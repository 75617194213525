import { createStore } from 'vuex';
import getUser from './api/getUser';
import getProjects from './api/getProjects';

const store = createStore({
    state() {
        return {
            user: null,
            projects: [],
            roles: {
                employee: 1,
                super_viser: 2,
                manager: 3,
                admin: 4
            }
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setProjects(state, projects) {
            state.projects = projects;
        },
        removeAll(state) {
            state.user = null;
            state.employees = null;
        }
    },
    actions: {
        async setAllSetters({ commit }) {
            commit('setUser', await getUser())
            commit('setProjects', await getProjects());
        },
        async setUser({ commit }) {
            commit('setUser', await getUser())
        },
        async setProjects({ commit }) {
            commit('setProjects', await getProjects());
        }
    },
})

export default store;
