import App from './App.vue'
import { createApp } from 'vue'
// import * as Sentry from "@sentry/vue";

import router from '@/router/router'
import store from './store.js'

const app = createApp(App);

// Sentry.init({
//   app,
//   dsn: "https://f3775b36e71e4fcaa297f08371d8c009@sentry.teamforce.ru/5",
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ["localhost", "home.kayros-it.ru", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

app.use(router);
app.use(store);
app.mount("#app");
