import ReportManagerPage from '@/pages/ReportManagerPage.vue';
import ReportUserPage from '@/pages/ReportUserPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import LogoutPage from '@/pages/LogoutPage.vue';
import NotFound from '@/pages/NotFoundPage.vue';
import HomePage from '@/pages/HomePage.vue'
import ChangePasswordPage from '@/pages/ChangePasswordPage.vue';
import ChangingHistoryPage from '@/pages/ChangingHistoryPage.vue';

export default [
    {
        name: 'home',
        path: '/',
        component: HomePage,
        meta: {requiresAuth: true},
    },{
        name: 'manage',
        path: '/manage',
        component: ReportManagerPage,
        meta: {requiresAuth: true, min_role: 2},
    },{
        name: 'History',
        path: '/history/:id',
        component: ChangingHistoryPage,
        meta: {requiresAuth: true, min_role: 2},
    },{
        name: 'login',
        path: '/login',
        component: LoginPage,
    },{
        name: 'logout',
        path: '/logout',
        component: LogoutPage,
        meta: {requiresAuth: true},
    },{
        name: 'myReport',
        path: '/my-report',
        component: ReportUserPage,
        meta: {requiresAuth: true},
    },{
        name: 'changePassword',
        path: '/change-password',
        component: ChangePasswordPage,
        meta: {requiresAuth: true},
    },{
        name: 'notFound',
        path: '/:catchAll(.*)',
        component: NotFound,
    }
];
