<template>
  <div class="container">
    <br><br><br><br>
    <br><br><br><br>
    <h2>
      Добро пожаловать в онлайн-сервис таймшитирования учета рабочего времени внештатных специлистов.
    </h2>
    <span class="text-secondary">
      Версия 0.1.8
    </span>
    <br><br><br><br><br><br>
    <h4>
      KairosIT Управление ИТ-проектами - сервис для реализации проектов и коммуникаций участников проектной деятельности. 
    </h4>
    <br><br><br>
    <h4>
      Инструкция пользователя:
    </h4>
    <br>
    <h5>
      Специалисту:
    </h5>
    <ul>
      <li>
        Перейдите в раздел "Моя таблица"
      </li>
      <li>
        Регулярно заполняйте количество часов, отработанных на проекте(-ах) или укажите вид отсутствия (В - выходной, О - отпуск, Б - больничный)
      </li>
      <li>
        При возникновении вопросов (нет проекта, ошибка в данных) обратитесь в техподдержку support@kayros-it.ru
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>