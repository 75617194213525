<template>
  <div class="container">
    <br>
    <div class="d-flex lustify-content-center">
      <div class="rounded-4 shadow p-5 m-auto w-100">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>