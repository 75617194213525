<template>
<br><br>
<div class="my-width rounded-4 shadow">
    <div class="p-5 pb-4 border-bottom-0">
    <h1 class="fw-bold mb-0 fs-2">Вход</h1>
    </div>

    <div class="p-5 pt-0">
    <div>
        <div v-if="errors && errors.username">
        <small v-for="(msg, index) in errors.username" :key="index" id="emailHelp" class="form-text text-danger">{{ msg }}</small>
        </div>
        <div class="form-floating mb-3">
        <input v-model="username" type="text" class="form-control rounded-3" id="floatingInput" placeholder="Почта">
        <label for="floatingInput">Почта</label>
        </div>
        
        <div v-if="errors && errors.password">
        <small v-for="(msg, index) in errors.password" :key="index" id="emailHelp" class="form-text text-danger">{{ msg }}</small>
        </div>
        <div class="form-floating mb-3">
        <input v-model="password" type="password" class="form-control rounded-3" id="floatingPassword" placeholder="Пароль">
        <label for="floatingPassword">Пароль</label>
        </div>

        <div v-if="errors && errors.detail">
        <small v-for="(msg, index) in errors.detail" :key="index" id="emailHelp" class="form-text text-danger">{{ msg }}</small>
        </div>
        <button class="w-100 mb-2 btn btn-lg rounded-3 btn-dark" @click="login()">Войти</button>

    </div>
    </div>
</div>
</template>

<script>
import api from '@/api/api.js';
import store from '@/store';
import router from '@/router/router';

export default {
    data() {
    return {
        username: '',
        password: '',
        check: '',
        errors: '',
    }
    },
    methods: {
        async login() {
            await api.post('api/token/', {
            username: this.username,
            password: this.password
            }).then(response => {
                if (response.status == 200) {
                    localStorage.setItem('token', response.data.access);
                    store.dispatch('setAllSetters');
                    router.push('/');
                }
            }).catch(error => {
            console.log(error);
            if (error.response.data) {
                this.errors = error.response.data;
            }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.my-width {
max-width: max-content;
margin: auto;
}
</style>