<template>
  <div class="d-flex justify-content-center vh-100">
    <div class="card p-5 m-auto">
      <hr>
      <button class="btn btn-dark w-100" @click="logOut()">Выйти</button>
    </div>
  </div>
</template>

<script>
import logOut from '@/api/logOut.js';

export default {
  data () {
    return {
      logOut: logOut,
    }
  }
}
</script>

<style lang="scss" scoped>
</style>